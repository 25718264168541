const Default = {
  borderRadius: "10px",
  hoverCardWidth: "500px",
  accentColor: "#f7931e",
  accentLighterColor: "#dd9745",
  accentTextColor: "#1a1a1a",
  criticalColor: "#e91619",
  criticalLighterColor: "#b72426",
  criticalTextColor: "#fff",
  primaryTextColor: "#fff",
  secondaryTextColor: "#ccc",
  tertiaryTextColor: "#aaa",
  primaryColor: "#1a1a1a",
  secondaryColor: "#2a2a2a",
  tertiaryColor: "#3a3a3a",
  quaternaryColor: "#4a4a4a",
  quinaryColor: "#5a5a5a",
  senaryColor: "#6a6a6a",
  septenaryColor: "#7a7a7a",
  bannerPrimaryColor: "#2a2a2a",
  bannerSecondaryColor: "#3a3a3a",
  bannerPrimaryTextColor: "#fff",
  bannerSecondaryTextColor: "#ddd",
  bannerTertiaryTextColor: "#ccc",
  bannerBottomBorderColor: "#3a3a3a",
  bannerOverlayShadow: "#1a1a1add",
  modalPrimaryColor: "#2a2a2a",
  modalSecondaryColor: "#3a3a3a",
  modalTertiaryColor: "#4a4a4a",
};

export default Default;
