const DimLogo = () => (
  <svg
    className="logo"
    style={{ overflow: "hidden" }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 22.979"
  >
    <g transform="translate(-454.7 -509.13)">
      <path
        d="M763.788,509.665l5.449,5.449h0a1.819,1.819,0,0,1,.4.594,1.836,1.836,0,0,1,0,1.4,1.822,1.822,0,0,1-.4.594l-5.449,5.449a1.822,1.822,0,0,1-.594.4,1.833,1.833,0,0,1-1.4,0,1.818,1.818,0,0,1-.594-.4l-5.449-5.449a1.821,1.821,0,0,1-.4-.594,1.835,1.835,0,0,1,.4-2h0l5.449-5.449h0a1.836,1.836,0,0,1,2.592,0Zm4.915,5.983-5.449-5.449h0a1.067,1.067,0,0,0-.347-.232,1.089,1.089,0,0,0-.416-.081,1.074,1.074,0,0,0-.763.313h0l-5.449,5.449a1.061,1.061,0,0,0-.233.347,1.1,1.1,0,0,0,0,.831,1.068,1.068,0,0,0,.232.348h0l5.449,5.449h0a1.06,1.06,0,0,0,.347.232,1.1,1.1,0,0,0,.83,0,1.06,1.06,0,0,0,.348-.233l5.449-5.449h0a1.072,1.072,0,0,0,.232-.348,1.1,1.1,0,0,0,0-.831A1.067,1.067,0,0,0,768.7,515.648Z"
        transform="translate(-291.679)"
        fill="var(--primaryTextColor)"
      />
      <path
        d="M1069.285,808.838l5.748,5.747a1.54,1.54,0,0,1,0,2.172l-2.784,2.784V817l-1.361-1.754V820.9h0l-1.6,1.6a1.54,1.54,0,0,1-2.172,0l-1.6-1.6v-5.656L1064.15,817v2.541l-2.784-2.784a1.54,1.54,0,0,1,0-2.172l5.748-5.747A1.54,1.54,0,0,1,1069.285,808.838Z"
        transform="translate(-588.781 -290.843)"
        fill="var(--primaryTextColor)"
        fillRule="evenodd"
      />
      <path
        d="M463.278,796.085l5.449,5.449a1.816,1.816,0,0,1,.4.594,1.833,1.833,0,0,1,0,1.4,1.818,1.818,0,0,1-.4.593h0l-5.449,5.449h0a1.814,1.814,0,0,1-.594.4,1.836,1.836,0,0,1-1.4,0,1.813,1.813,0,0,1-.593-.4h0l-5.449-5.449a1.84,1.84,0,0,1-.535-1.3,1.834,1.834,0,0,1,.536-1.3h0l5.449-5.449a1.839,1.839,0,0,1,2.593,0Zm-2.031,12.985V796.59l-.029.028h0l-5.449,5.449a1.067,1.067,0,0,0-.233.347,1.108,1.108,0,0,0,0,.831,1.068,1.068,0,0,0,.233.347l5.449,5.449Z"
        transform="translate(0 -278.003)"
        fill="var(--primaryTextColor)"
      />
      <rect
        width="2.059"
        height="13.803"
        rx="1.03"
        transform="translate(469.782 509.51)"
        fill="var(--primaryTextColor)"
      />
    </g>
  </svg>
);

export default DimLogo;
