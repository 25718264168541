import "./Bar.scss";

function BarLoad() {
  return (
    <div className="barLoad">
      <div />
    </div>
  );
}

export default BarLoad;
