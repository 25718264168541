const LightsOff = {
  borderRadius: "10px",
  hoverCardWidth: "500px",
  accentColor: "#f7931e",
  accentLighterColor: "#dd9745",
  accentTextColor: "#1a1a1a",
  criticalColor: "#e91619",
  criticalLighterColor: "#b72426",
  criticalTextColor: "#eee",
  primaryTextColor: "#eee",
  secondaryTextColor: "#ccc",
  tertiaryTextColor: "#aaa",
  primaryColor: "#000",
  secondaryColor: "#101010",
  tertiaryColor: "#000",
  quaternaryColor: "#202020",
  quinaryColor: "#303030",
  senaryColor: "#404040",
  septenaryColor: "#505050",
  bannerPrimaryColor: "#101010",
  bannerSecondaryColor: "#202020",
  bannerPrimaryTextColor: "#eee",
  bannerSecondaryTextColor: "#ccc",
  bannerTertiaryTextColor: "#bbb",
  bannerBottomBorderColor: "#303030",
  bannerOverlayShadow: "#151515cc",
  modalPrimaryColor: "#000",
  modalSecondaryColor: "#101010",
  modalTertiaryColor: "#202020",
};

export default LightsOff;
